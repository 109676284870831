import store from '@/store'
import { event } from 'vue-gtag-next'
import { defineAsyncComponent } from 'vue'

const showErrorMessage = (errorMessage, errorDetail, errorHandler) => {
    store.commit('set', {
        showErrorModal: true,
        errorMessage: errorMessage,
        errorDetail: errorDetail,
        errorHandler: errorHandler
    })
}
const setGaEvent = function (eventObj, route, pathSetting) {
    /*事件category的路由名稱後面是否要加params*/
    // eventObj = {
    //     label: '',
    //     action: '',
    //     value: ''
    // }
    let params = ''
    if (pathSetting?.params) params = '/' + pathSetting.params
    else if (route?.params?.mType) params = '/' + route?.params?.mType
    else if (route?.params?.mode) params = '/' + route?.params?.mode

    const routeName = route?.name
    const category = routeName + params

    /*事件label的觸發點是否在modal跳窗中*/
    let modal = ''
    if (pathSetting?.isModal || store.state.isShowModalForGa) modal = '(modal)'

    const eventLabel = eventObj?.label + modal

    if (eventObj?.value)
        event(eventObj?.action, {
            event_category: category,
            event_label: eventLabel,
            value: parseInt(eventObj?.value)
        })
    else
        event(eventObj?.action, {
            event_category: category,
            event_label: eventLabel
        })
}

const lazyLoadComponent = (route) => {
    return defineAsyncComponent(() => import(`@/${route}.vue`))
}

export { showErrorMessage, setGaEvent, lazyLoadComponent }
